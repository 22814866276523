@import '@/styles/variables.scss';




















































.not-found-page {
  padding: 20px;
  min-height: calc(100vh - 40px);
  &__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  &__content {
    max-width: 530px;
    margin-top: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      text-align: center;
      color: #2830ce;
      margin: 24px 0;
    }
    &-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #46474a;
      margin-bottom: 40px;
    }
  }
}
